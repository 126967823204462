// src/Library/hotelLibrary.js

const hotels = [
    {
        id: 1,
        name: "Luxury Golf Resort & Spa",
        description: "Experience unparalleled luxury and serenity at our 5-star golf resort, offering state-of-the-art amenities, gourmet dining, and access to world-class golf courses.",
        images: [
            require('../images/GoldPackageItineraryImages/image1.jpg'),
            require('../images/GoldPackageItineraryImages/image2.jpg'),
            require('../images/GoldPackageItineraryImages/image3.jpg'),
            require('../images/GoldPackageItineraryImages/image1.jpg'),
            require('../images/GoldPackageItineraryImages/image2.jpg'),
            require('../images/GoldPackageItineraryImages/image3.jpg')
        ],
        specifications: {
            keyFeatures: ["5-star accommodations with stunning views", "Direct access to nearby golf courses", "Spa & wellness center for relaxation", "Fine dining options with gourmet meals"],
            packageDetails: ["1 x Accommodation", "1 x Spa session", "1 x Access to golf course", "1 x Gourmet meal experience"],
            generalSpecifications: [
                { label: "Location", value: "Secluded coastal area with direct access to the beach" },
                { label: "Room Size", value: "45 square meters" },
                { label: "Check-in Time", value: "3:00 PM" },
                { label: "Check-out Time", value: "12:00 PM" },
                { label: "Special Features", value: "Personalized service, exclusive golfing experience" }
            ]
        },
        customerFeedback: {
            overallRating: 4.5,
            ratings: [
                { star: 5, percentage: 75 },
                { star: 4, percentage: 20 },
                { star: 3, percentage: 5 }
            ]
        }
    }
];

export default hotels;
