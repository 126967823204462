import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import itineraries from '../Library/itineraryLibrary';
import '../Style/ItineraryDetails.css'; // Ensure the CSS file is imported

const ItineraryDetails = () => {
    const { slug } = useParams();
    const [currentItinerary, setCurrentItinerary] = useState(null);
    const [currentDayIndex, setCurrentDayIndex] = useState(0);

    useEffect(() => {
        setCurrentItinerary(itineraries[slug]);
    }, [slug]);

    if (!currentItinerary) return <div>Itinerary not found!</div>;

    const currentDay = currentItinerary.days[currentDayIndex];

    return (
        <div className="itinerary-page">
            {/* Hero Section */}
            <header className="itinerary-hero">
                <h1>{currentItinerary.name} Itinerary</h1>
                <p>Experience Luxury, Adventure, Golf & Business Networking with us!</p>
            </header>

            {/* Navigation Bar */}
            <nav className="itinerary-navbar">
                <ul>
                    <li><a href="#itinerary">Itinerary</a></li>
                    <li><a href="#included">Included</a></li>
                    <li><a href="#excluded">Excluded</a></li>
                    <li><a href="#contact">Contact Us</a></li>
                </ul>
            </nav>

            {/* Timeline Itinerary */}
            <section id="itinerary" className="itinerary-section">
                <h2>Quick View!</h2>
                <div className="itinerary-timeline">
                    {currentItinerary.days.map((day, index) => (
                        <div className={`${currentDayIndex === index ? 'itinerary-show' : ''}`} key={index}>
                            <div className="itinerary-circle"></div>
                            <div className="itinerary-timeline-content">
                                <h3>{day.title}</h3>
                                <ul>
                                    {day.timelineItems.map((item, i) => (
                                        <li key={i}>{item}</li>
                                    ))}
                                </ul>
                                {/* Carousel */}
                                <div className="itinerary-carousel">
                                    {day.carouselImages1.map((img, idx) => (
                                        <img key={idx} src={img} alt={`Carousel Image ${idx + 1}`} />
                                    ))}
                                </div>
                                <div className="itinerary-carousel2">
                                    {day.carouselImages2.map((img, idx) => (
                                        <img key={idx} src={img} alt={`Carousel Image ${idx + 1}`} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            {/* Included Section */}
            <section id="included" className="itinerary-section">
                <h2>What's Included</h2>
                <ul className="itinerary-features-list">
                    {currentItinerary.days.find(day => day.type === 'included')?.timelineItems.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            </section>

            {/* Excluded Section */}
            <section id="excluded" className="itinerary-section">
                <h2>What's Excluded</h2>
                <ul className="itinerary-features-list">
                    {currentItinerary.days.find(day => day.type === 'excluded')?.timelineItems.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            </section>
            
            {/* Contact Section */}
            <section id="contact" className="itinerary-contact-section">
                <h2>Contact Us</h2>
                <p>📍 Visit us at: LuxOdyssey Safaris, Nairobi, Kenya</p>
                <p>📧 Email: info@luxodysseysafaris.com</p>
                <p>📞 Phone: +254 734-338-794</p>
            </section>

            {/* Footer */}
            <footer className="itinerary-footer">
                <p>&copy; 2025 LuxOdyssey Safaris. All rights reserved.</p>
            </footer>
        </div>
    );
};

export default ItineraryDetails;
