import React, { useEffect, useState } from "react";
import "../Style/privacy.css";

const PrivacyPolicy = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 100);
  }, []);

  return (
    <div className={`privacy-container fade-in ${isVisible ? "slide-in" : ""}`}>
      <h1>Privacy Policy</h1>
      <p>At LuxOdysseys Safaris, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your personal data when you interact with our website and services.</p>

      <h2 className={isVisible ? "slide-in" : ""}>1. Information We Collect</h2>
      <h3 className={isVisible ? "slide-in" : ""}>1.1. Personal Information</h3>
      <ul>
        <li>Name, email, phone number, billing address.</li>
        <li>Payment details (processed securely through third-party payment providers).</li>
        <li>Passport details (only when required for bookings).</li>
      </ul>

      <h3 className={isVisible ? "slide-in" : ""}>1.2. Non-Personal Information</h3>
      <ul>
        <li>IP address, browser type, and device information.</li>
        <li>Website usage statistics for analytics and service improvement.</li>
      </ul>

      <h2 className={isVisible ? "slide-in" : ""}>2. How We Use Your Information</h2>
      <p>To process bookings and payments.</p>
      <p>To provide AI-curated travel recommendations.</p>
      <p>To communicate with clients regarding their bookings.</p>
      <p>For marketing and promotional purposes (clients can opt out anytime).</p>

      <h2 className={isVisible ? "slide-in" : ""}>3. Data Security</h2>
      <p>We implement industry-standard security measures to protect personal data.</p>
      <p>Sensitive payment information is encrypted and handled by secure third-party payment processors.</p>

      <h2 className={isVisible ? "slide-in" : ""}>4. Sharing of Information</h2>
      <p>We do not sell or rent personal data. However, we may share information:</p>
      <ul>
        <li>With travel partners for booking confirmation.</li>
        <li>With payment processors to facilitate transactions.</li>
        <li>If required by law or for fraud prevention.</li>
      </ul>

      <h2 className={isVisible ? "slide-in" : ""}>5. Cookies and Tracking Technologies</h2>
      <p>We use cookies to enhance user experience and improve website functionality.</p>
      <p>Users can manage cookie preferences through browser settings.</p>

      <h2 className={isVisible ? "slide-in" : ""}>6. Your Rights</h2>
      <ul>
        <li>Request access to your personal data.</li>
        <li>Request correction or deletion of your data.</li>
        <li>Opt-out of marketing communications.</li>
      </ul>

      <h2 className={isVisible ? "slide-in" : ""}>7. Changes to This Policy</h2>
      <p>We may update this Privacy Policy from time to time. Clients will be notified of any major changes.</p>

      <h2 className={isVisible ? "slide-in" : ""}>8. Contact Information</h2>
      <p>For any privacy-related inquiries, contact us at:</p>
      <p>📧 Email: support@luxodysseysafaris.com</p>
    </div>
  );
};

export default PrivacyPolicy;
