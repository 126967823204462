// src/App.js

import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Background from "./Components/Background";
//import NavBar from "./Components/Navbar";
import ContactHeader from "./Components/ContactHeader";
import Header from "./Components/Header";
import Home from "./Components/Home";
import Checkout from "./Components/Checkout";
import Search from "./Components/Search";
import Login from "./Components/Login";
import Footer from "./Components/Footer";
import Categories from "./Components/Categories";
import Error from "./Components/Error";
import Itinerary from "./Components/Itinerary";
import TermsAndConditions from "./Components/TermsAndConditions";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import LuxuryHotelDetails from "./Components/LuxuryHotelDetails";
import ItineraryDetails from './Components/ItineraryDetails';

import ContactUs from "./Components/ContactUs";
import Team from './Components/Team';      // Your about us page
//import Blog from './Blog';            // Your blog page
import { useStateValue } from "./ContextAPI/StateProvider";
import { auth } from "./Firebase/Firebase";
import { Box } from '@mui/material';
import '@fortawesome/fontawesome-free/css/all.min.css';
import ScrollToTop from "./Components/ScrollToTop";

function App() {
    const [, dispatch] = useStateValue();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((authUser) => {
            if (authUser) {
                dispatch({
                    type: "SET_USER",
                    user: authUser,
                });
            } else {
                dispatch({
                    type: "SET_USER",
                    user: null,
                });
            }
        });

        return () => unsubscribe();
    }, [dispatch]);

    return (
        <Router>
            <Box className="App">
                <Background />
                <ContactHeader />
                <Header />
                <ScrollToTop />

                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/checkout" element={<Checkout />} />
                    <Route path="/search" element={<Search />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/itinerary/:slug" element={<Itinerary />} />
                    <Route path="/itineraries" element={<Categories section="Itineraries" />} />
                    <Route path="/circuits" element={<Categories section="Circuits" />} />
                    <Route path="/golf-courses" element={<Categories section="Golf Courses" />} />
                    <Route path="/excursions" element={<Categories section="Excursions" />} />
                    <Route path="/hotels" element={<Categories section="Hotels" />} />
                    <Route path="/category/:category" element={<Categories />} />
                    <Route path="/contact" element={<ContactUs />} />
                    <Route path="/about" element={<Team />} />
                    <Route path="/Terms-&-Conditions" element={<TermsAndConditions />} />
                    <Route path="/Privacy-Policy" element={<PrivacyPolicy />} />
                    <Route path="/LuxuryHotelDetails" element={<LuxuryHotelDetails />} />
                    <Route path="/listed-itinerary-details/:slug" element={<ItineraryDetails />} />
                    <Route path="*" element={<Error product="Not Found" />} />
                </Routes>
                <Footer />
            </Box>
        </Router>
    );
}

export default App;
