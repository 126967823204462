import React, { useState } from "react";
import '../Style/ItinerarySearchForm.css';
import SearchIcon from '@mui/icons-material/Search';

const ItinerarySearchForm = () => {
  const [showCountries, setShowCountries] = useState(false);
  const [showGuests, setShowGuests] = useState(false);
  const [showActivities, setShowActivities] = useState(false);
  const [showRooms, setShowRooms] = useState(false);
  const [showTransferModes, setShowTransferModes] = useState(false);

  const [selectedCountries, setSelectedCountries] = useState([]);
  const [guests, setGuests] = useState({ adults: 0, youth: 0, children: 0, toddlers: 0 });
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [rooms, setRooms] = useState({ presidential: 0, honeymoon: 0, penthouse: 0, juniorSuite: 0, suite: 0, singleRoom: 0, doubleRoom: 0, cabana: 0 });
  const [selectedTransferModes, setSelectedTransferModes] = useState([]);

  const toggleDropdown = (dropdown) => {
    setShowCountries(dropdown === "countries");
    setShowGuests(dropdown === "guests");
    setShowActivities(dropdown === "activities");
    setShowRooms(dropdown === "rooms");
    setShowTransferModes(dropdown === "transferModes");
  };

  const handleCountryChange = (country) => {
    if (selectedCountries.includes(country)) {
      setSelectedCountries(selectedCountries.filter((c) => c !== country));
    } else {
      setSelectedCountries([...selectedCountries, country]);
    }
  };

  const handleGuestChange = (type, value) => {
    setGuests({ ...guests, [type]: value < 0 ? 0 : value });
  };

  const handleActivityChange = (activity) => {
    if (selectedActivities.includes(activity)) {
      setSelectedActivities(selectedActivities.filter((a) => a !== activity));
    } else {
      setSelectedActivities([...selectedActivities, activity]);
    }
  };

  const handleRoomChange = (type, value) => {
    setRooms({ ...rooms, [type]: value < 0 ? 0 : value });
  };

  const handleTransferModeChange = (mode) => {
    if (selectedTransferModes.includes(mode)) {
      setSelectedTransferModes(selectedTransferModes.filter((m) => m !== mode));
    } else {
      setSelectedTransferModes([...selectedTransferModes, mode]);
    }
  };

  return (
    <div className="container10">
      <div className="trip-section">
        <div className="trip-container">
          <h1 className="main-heading">Choose Your Trip</h1>
          <p className="description">
            Looking for your dream vacation destination but don’t know where to start? Let us help you craft the perfect, unforgettable escape to your dream vacation.
          </p>
        </div>
      </div>

      <div className="search-bar">
        <div className="search-option">
          <div className="option-label" onClick={() => toggleDropdown("countries")}>
            Where
          </div>
          {showCountries && (
            <div className="dropdown">
              {["Kenya", "Rwanda", "UAE", "USA", "Zanzibar", "Tanzania"].map((country) => (
                <label key={country}>
                  <input
                    type="checkbox"
                    checked={selectedCountries.includes(country)}
                    onChange={() => handleCountryChange(country)}
                  />
                  {country}
                </label>
              ))}
              <button onClick={() => setShowCountries(false)}>Apply</button>
            </div>
          )}
          <div className="selected-options" onClick={() => toggleDropdown("countries")}>
            {selectedCountries.length > 0 ? (
              <strong>Selected Countries:</strong>
            ) : (
              <strong>Select Country</strong>
            )}
            {selectedCountries.length > 0 && (
              <span>{selectedCountries.join(", ")}</span>
            )}
          </div>
        </div>
        <div className="divider"></div>
        <div className="search-option">
          <div className="option-label">From Date</div>
          <input type="date" />
        </div>
        <div className="divider"></div>
        <div className="search-option">
          <div className="option-label">To Date</div>
          <input type="date" />
        </div>
        <div className="divider"></div>
        <div className="search-option">
          <div className="option-label" onClick={() => toggleDropdown("guests")}>
            Guests
          </div>
          {showGuests && (
            <div className="dropdown">
              {Object.entries(guests).map(([type, value]) => (
                <div key={type} className="guest-control">
                  <span>{type}</span>
                  <div>
                    <button onClick={() => handleGuestChange(type, value - 1)}>-</button>
                    <input
                      type="number"
                      value={value}
                      onChange={(e) => handleGuestChange(type, parseInt(e.target.value))}
                    />
                    <button onClick={() => handleGuestChange(type, value + 1)}>+</button>
                  </div>
                </div>
              ))}
              <button onClick={() => setShowGuests(false)}>Apply</button>
            </div>
          )}
          <div className="selected-options" onClick={() => toggleDropdown("guests")}>
            {Object.values(guests).some((value) => value > 0) ? (
              <strong>Selected Guests:</strong>
            ) : (
              <strong>Select Guests</strong>
            )}
            {Object.values(guests).some((value) => value > 0) && (
              <span>
                {Object.entries(guests)
                  .filter(([_, value]) => value > 0)
                  .map(([type, value]) => `${value} ${type}`)
                  .join(", ")}
              </span>
            )}
          </div>
        </div>
      </div>

      <div className="itinerary-search-bar">
        <div className="search-option">
          <div className="option-label" onClick={() => toggleDropdown("activities")}>
            Activities
          </div>
          {showActivities && (
            <div className="dropdown">
              {["Golf", "Safari", "Heli Tour", "Beach"].map((activity) => (
                <label key={activity}>
                  <input
                    type="checkbox"
                    checked={selectedActivities.includes(activity)}
                    onChange={() => handleActivityChange(activity)}
                  />
                  {activity}
                </label>
              ))}
              <button onClick={() => setShowActivities(false)}>Apply</button>
            </div>
          )}
          <div className="selected-options" onClick={() => toggleDropdown("activities")}>
            {selectedActivities.length > 0 ? (
              <strong>Selected Activities:</strong>
            ) : (
              <strong>Select Activities</strong>
            )}
            {selectedActivities.length > 0 && (
              <span>{selectedActivities.join(", ")}</span>
            )}
          </div>
        </div>
        <div className="divider"></div>
        <div className="search-option">
          <div className="option-label" onClick={() => toggleDropdown("rooms")}>
            No. of Rooms
          </div>
          {showRooms && (
            <div className="dropdown">
              {Object.entries(rooms).map(([type, value]) => (
                <div key={type} className="room-control">
                  <span>{type}</span>
                  <div>
                    <button onClick={() => handleRoomChange(type, value - 1)}>-</button>
                    <input
                      type="number"
                      value={value}
                      onChange={(e) => handleRoomChange(type, parseInt(e.target.value))}
                    />
                    <button onClick={() => handleRoomChange(type, value + 1)}>+</button>
                  </div>
                </div>
              ))}
              <button onClick={() => setShowRooms(false)}>Apply</button>
            </div>
          )}
          <div className="selected-options" onClick={() => toggleDropdown("rooms")}>
            {Object.values(rooms).some((value) => value > 0) ? (
              <strong>Selected Rooms:</strong>
            ) : (
              <strong>Select Rooms</strong>
            )}
            {Object.values(rooms).some((value) => value > 0) && (
              <span>
                {Object.entries(rooms)
                  .filter(([_, value]) => value > 0)
                  .map(([type, value]) => `${value} ${type}`)
                  .join(", ")}
              </span>
            )}
          </div>
        </div>
        <div className="divider"></div>
        <div className="search-option">
          <div className="option-label" onClick={() => toggleDropdown("transferModes")}>
            Transfer Mode
          </div>
          {showTransferModes && (
            <div className="dropdown">
              {["Car", "Air", "Yacht"].map((mode) => (
                <label key={mode}>
                  <input
                    type="checkbox"
                    checked={selectedTransferModes.includes(mode)}
                    onChange={() => handleTransferModeChange(mode)}
                  />
                  {mode}
                </label>
              ))}
              <button onClick={() => setShowTransferModes(false)}>Apply</button>
            </div>
          )}
          <div className="selected-options" onClick={() => toggleDropdown("transferModes")}>
            {selectedTransferModes.length > 0 ? (
              <strong>Selected Transfer Modes:</strong>
            ) : (
              <strong>Select Transfer Mode</strong>
            )}
            {selectedTransferModes.length > 0 && (
              <span>{selectedTransferModes.join(", ")}</span>
            )}
          </div>
        </div>
        <div className="divider"></div>
        <div className="search-option-last">
          <div className="search-button">
            <span className="search-icon"><SearchIcon /></span> Search Itinerary
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItinerarySearchForm;