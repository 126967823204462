import React, { useEffect, useState } from "react";
import "../Style/terms.css";

const TermsAndConditions = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(true);
    }, 100); // Delay to trigger animation
  }, []);

  return (
    <div className={`terms-container fade-in ${isVisible ? "slide-in" : ""}`}>
      <h1>Terms and Conditions</h1>
      <p>Welcome to LuxOdysseys Safaris <strong>("Company," "we," "our," or "us")</strong>. These Terms and Conditions govern 
        your use of our website and services, including booking safari tours, purchasing itineraries, 
        and accessing AI-curated travel recommendations. By using our website and services, you agree to 
        abide by these Terms and Conditions.</p>

      <h2 className={isVisible ? "slide-in" : ""}>1. Definitions</h2>
      <ul>
        <li><strong>Client/You:</strong> Any individual, group, or organization using our website or services.</li>
        <li><strong>Services:</strong> Travel and tour packages, AI-generated itineraries, and related services.</li>
        <li><strong>Website:</strong> The online platform where our services are offered.</li>
      </ul>

      <h2 className={isVisible ? "slide-in" : ""}>2. Booking and Payment</h2>
      <ul>
        <li>All bookings are subject to availability.</li>
        <li>Clients must provide accurate personal and payment information.</li>
        <li>Payments must be made in full before the trip commences unless otherwise agreed upon.</li>
        <li>Cancellations and refunds are subject to our cancellation policy.</li>
      </ul>

      <h2 className={isVisible ? "slide-in" : ""}>3. AI-Generated Itineraries</h2>
      <ul>
        <li>AI-based recommendations are generated using customer preferences and trip duration.</li>
        <li>We strive for accuracy, but itineraries may be subject to external changes (e.g., weather conditions, local regulations).</li>
      </ul>

      <h2 className={isVisible ? "slide-in" : ""}>4. Cancellation and Refund Policy</h2>
      <ul>
        <li>Cancellations made <strong>30 days</strong> before departure: Full refund minus processing fees.</li>
        <li>Cancellations made <strong>15–30 days</strong>15–30 days before departure: 50% refund.</li>
        <li>Cancellations made less than <strong>15 days</strong> before departure: No refund.</li>
      </ul>

      <h2 className={isVisible ? "slide-in" : ""}>5. Travel Insurance</h2>
      <ul>
        <li>We strongly advise all clients to obtain travel insurance covering medical emergencies, trip cancellations, and loss of personal belongings.</li>
      </ul>

      <h2 className={isVisible ? "slide-in" : ""}>6. Liability and Responsibility</h2>
      <ul>
        <li>LuxOdysseys Safaris is not responsible for delays, cancellations, injuries, losses, or damages caused by third-party service providers, natural disasters, or other unforeseeable events.</li>
        <li>Clients must comply with all local laws and regulations of their travel destinations.</li>
      </ul>

      <h2 className={isVisible ? "slide-in" : ""}>7. User Accounts and Security</h2>
      <ul>
        <li>Clients may be required to create an account to access certain features.</li>
        <li>Users are responsible for maintaining the confidentiality of their login credentials.</li>
      </ul>

      <h2 className={isVisible ? "slide-in" : ""}>8. Prohibited Activities</h2>
      <ul>
        <li>Unauthorized reselling of our itineraries.</li>
        <li>Misuse of our AI system or hacking attempts.</li>
        <li>Posting fraudulent or misleading information.</li>
      </ul>

      <h2 className={isVisible ? "slide-in" : ""}>9. Intellectual Property</h2>
      <ul>
        <li>All website content, including itineraries and AI-generated recommendations, is the property of LuxOdysseys Safaris. Unauthorized reproduction is prohibited.</li>
      </ul>

      <h2 className={isVisible ? "slide-in" : ""}>10. Governing Law</h2>
      <ul>
        <li>These Terms and Conditions are governed by the laws of Kenya. Any disputes will be resolved in courts within TOURIST INDUSTRY LICENSING ACT.</li>
      </ul>

      <h2 className={isVisible ? "slide-in" : ""}>11. Amendments</h2>
      <ul>
        <li>We reserve the right to update these Terms and Conditions at any time. Clients will be notified of major changes.</li>
      </ul>
    </div>
  );
};

export default TermsAndConditions;
