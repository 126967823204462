// src/Components/LuxuryHotelDetails.jsx

import React, { useState } from 'react';
import hotels from '../Library/hotelLibrary';
import '../Style/styles.css';

const LuxuryHotelDetails = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const hotel = hotels[0]; // Assuming we're displaying the first hotel

    const nextSlide = () => {
        setCurrentSlide((prev) => (prev + 1) % hotel.images.length);
    };

    const prevSlide = () => {
        setCurrentSlide((prev) => (prev - 1 + hotel.images.length) % hotel.images.length);
    };

    return (
        <div className="content-wrapper">
            <div className="row">
                <div className="hotel-slideshow-container">
                    <div className="slideshow-slide" style={{ display: 'block' }}>
                        <div className="slide-number">{currentSlide + 1} / {hotel.images.length}</div>
                        <img src={hotel.images[currentSlide]} style={{ width: '100%' }} alt={hotel.name} />
                    </div>
                    <button className="slideshow-prev" onClick={prevSlide}>❮</button>
                    <button className="slideshow-next" onClick={nextSlide}>❯</button>
                    <div className="caption-container">
                        <p className="caption">{hotel.name}</p>
                    </div>
                    <div className="thumbnail-row">
                        {hotel.images.map((image, index) => (
                            <div className="thumbnail-column" key={index}>
                                <img 
                                    className="thumbnail-image" 
                                    src={image} 
                                    style={{ width: '100%' }} 
                                    onClick={() => setCurrentSlide(index)} 
                                    alt={`Thumbnail ${index + 1}`} 
                                />
                            </div>
                        ))}
                    </div>
                </div>

                <div className="add-to-cart-section">
                    <div className="navigation-menu">
                        <nav className="menu-links">
                            <button className="menu-item" onClick={() => document.getElementById('description').scrollIntoView()}>Hotel Details</button>
                            <button className="menu-item" onClick={() => document.getElementById('specifications').scrollIntoView()}>Specifications</button>
                            <button className="menu-item" onClick={() => document.getElementById('feedback').scrollIntoView()}>Customer Feedback</button>
                        </nav>
                    </div>

                    <div className="hotel-info-card">
                        <div className="hotel-summary">
                            <img src={hotel.images[0]} alt={hotel.name} className="hotel-thumbnail" />
                            <div className="hotel-summary-details">
                                <h3 className="hotel-name">{hotel.name}</h3>
                            </div>
                        </div>
                        <form className="cart-form" method="post" action="/cart/products/LUXURY-HOTEL-SKU/quantity/">
                            <button type="button" className="add-to-cart-btn">Add to Tour Cart</button>
                            <input name="capiId" type="hidden" value="uniqueCartIdHere" />
                            <input name="csrfToken" type="hidden" value="csrfTokenHere" />
                        </form>
                    </div>
                </div>
            </div>

            <div className="hotel-details-section">
                <div id="description">
                    <header className="section-header"><h2>Hotel Details</h2></header>
                    <div className="details-content">
                        <p>{hotel.description}</p>
                        <h3>{hotel.name}</h3>
                        <ul className="details-list">
                            {hotel.specifications.keyFeatures.map((feature, index) => (
                                <li key={index}>{feature}</li>
                            ))}
                        </ul>
                        <h3>What’s in the Experience</h3>
                        <table className="details-table">
                            <tbody>
                                {hotel.specifications.packageDetails.map((item, index) => (
                                    <tr key={index}><td>{item}</td></tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="specifications-section">
                    <div id="specifications">
                        <header className="section-header"><h2>Specifications</h2></header>
                        <div className="specifications-content">
                            <article className="specification-card">
                                <h3>Key Features</h3>
                                <ul className="features-list">
                                    {hotel.specifications.keyFeatures.map((feature, index) => (
                                        <li key={index}>{feature}</li>
                                    ))}
                                </ul>
                            </article>
                            <article className="specification-card">
                                <h3>What’s in the package</h3>
                                <table className="package-table">
                                    <tbody>
                                        {hotel.specifications.packageDetails.map((item, index) => (
                                            <tr key={index}><td>{item}</td></tr>
                                        ))}
                                    </tbody>
                                </table>
                            </article>
                            <article className="specification-card">
                                <h3>Specifications</h3>
                                <ul className="spec-list">
                                    {hotel.specifications.generalSpecifications.map((spec, index) => (
                                        <li key={index}><strong>{spec.label}:</strong> {spec.value}</li>
                                    ))}
                                </ul>
                            </article>
                        </div>
                    </div>
                </div>

                <div className="customer-feedback-section">
                    <div id="feedback">
                        <header className="section-header"><h2>Customer Ratings</h2></header>
                        <div className="feedback-content">
                            <p>Overall Rating: <strong>{hotel.customerFeedback.overallRating}</strong>/5</p>
                            {hotel.customerFeedback.ratings.map((rating) => (
                                <div className="rating-bar" key={rating.star}>
                                    <span>{rating.star} Stars</span>
                                    <div className="bar">
                                        <span style={{ width: `${rating.percentage}%` }}></span>
                                    </div>
                                    <span>{rating.percentage}%</span>
                                </div>
                            ))}
                            <button className="view-reviews-btn">View all reviews</button>
                        </div>
                    </div>
                    <div className="contact-button">
                        <button className="chat-btn">Chat</button>
                    </div>
                </div>
            </div>

            <section id="map-location-section">
                <h2>Map Location</h2>
                <div id="map"></div>
            </section>
        </div>
    );
};

export default LuxuryHotelDetails;
